import { required } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";

export default {
   data() {
      return {
         accordion: 2,
         validationSpaces: {
            ivrToken: false,
            ringToken: false,
            // smartStreetKey: false,
            // postalMethodsKey: false,
            // apiToken: false,
            // googleKey: false
         },
         signOptions: {
            penColor: "black",
         },
         showSignPlaceholder: true,
         showPhoneError: false,
         validPhoneNumber: false,
         isValidateNumber: false,
         checkeeperPhone: '',
         showError: false
      };
   },
   validations: {
      ivrToken: { required },
      ringToken: { required },
      // smartStreetKey: { required },
      // postalMethodsKey: { required },
      // apiToken: { required },
      // googleKey: { required }
   },

   computed: {
      ...mapState({
         companyAddressList: (state) => state.companyAddressesAdmin.addressLists,
         isOpenCompanySettings: (state) => state.manageAdminModule.modal.isOpen,
         isOpenProviderModal: (state) => state.addProviderModal.modal.isOpen,
         companyId: (state) => state.auth.user.provider.id,
         providerId: (state) => state.addProviderModal.providerId
      }),

      apiIntegrationsData() {
         return [
            {
               title: 'IVR Technology Group',
               logo: 'IVR-logo.svg',
               icon: 'IVR-icon.svg',
               link: 'https://www.ivrtechgroup.com',
               linkTitle: 'Website',
               rightButtonLabel: this.otherApiIntegration.isEditIvr ? 'Save' : 'Add',
               rightButtonFunc: this.saveIvrData,
               leftButtonFunc: this.cancelIvrData,
               disabled: true,
               readonly: true,
               flexInputs: [],
               inputs: [
                  {
                     name: 'ivrToken',
                     label: 'API token*',
                     value: this.ivrToken,
                     class: 'P-padding-100',
                     type: 'text'
                  },
               ]
            },
            {
               title: 'Ringcentral Messaging Management System',
               logo: 'RingCentral_Logo.svg',
               icon: 'RingCentral-icon.svg',
               link: 'https://www.ringcentral.com',
               linkTitle: 'Website',
               rightButtonLabel: this.otherApiIntegration.isEditRing ? 'Save' : 'Add',
               rightButtonFunc: this.saveRingCentralData,
               leftButtonFunc: this.cancelRingCentralData,
               disabled: true,
               readonly: true,
               flexInputs: [],
               inputs: [
                  {
                     name: 'ringToken',
                     label: 'API token*',
                     value: this.ringToken,
                     class: 'P-padding-100',
                     type: 'text'
                  },
               ]
            },
            {
               title: 'Checkeeper',
               logo: 'Checkeeper.svg',
               icon: 'Checkeeper-logo.svg',
               link: 'https://checkeeper.com',
               linkTitle: 'Website',
               rightButtonLabel: this.otherApiIntegration.isEditCheck ? 'Save' : 'Add',
               rightButtonFunc: this.saveCheckeeperData,
               leftButtonFunc: this.cancelCheckeeperData,
               disabled: false,
               readonly: false,
               flexInputs: [],
               inputs: [],
               isValid: this.otherApiIntegration.check.isValid
            },
            {
               title: 'Smartystreets',
               logo: 'smartystreets.svg',
               icon: 'smartystreets_mini.svg',
               link: 'https://www.smartystreets.com',
               linkTitle: 'Website',
               rightButtonLabel: this.otherApiIntegration.isEditSmartStreet ? 'Save' : 'Add',
               rightButtonFunc: this.saveSmartStreet,
               leftButtonFunc: this.cancelSmartStreet,
               disabled: false,
               readonly: false,
               flexInputs: [
                  //    {
                  //       name: 'smartyStreetAuthId',
                  //       label: 'Auth ID',
                  //       value: this.smartyStreetAuthId,
                  //       class: 'P-padding-50',
                  //       type: 'text'
                  //    },
                  //    {
                  //       name: 'smartyStreetAuthToken',
                  //       label: 'Auth Token',
                  //       value: this.smartyStreetAuthToken,
                  //       class: 'P-padding-50',
                  //       type: 'text'
                  //    },
               ],
               inputs: [
                  {
                     name: 'smartStreetKey',
                     label: 'Key',
                     value: this.smartStreetKey,
                     class: 'P-padding-100',
                     type: 'text',
                     title: 'Key'
                  },
               ],
               isValid: this.otherApiIntegration.smartStreet.isValid
            },
            {
               title: 'Postalmethods',
               logo: 'postal-methods.svg',
               icon: 'postal-methods_mini.svg',
               link: 'https://www.postalmethods.com',
               linkTitle: 'Website',
               rightButtonLabel: this.otherApiIntegration.isEditPostalMethods ? 'Save' : 'Add',
               rightButtonFunc: this.savePostalMethod,
               leftButtonFunc: this.cancelPostalMethod,
               disabled: false,
               readonly: false,
               flexInputs: [],
               inputs: [
                  {
                     name: 'postalMethodsKey',
                     label: 'Secret Key',
                     value: this.postalMethodsKey,
                     class: 'P-padding-100',
                     type: 'text',
                     title: 'Secret Key'
                  },
               ],
               isValid: this.otherApiIntegration.postalMethods.isValid
            },
            {
               title: 'TaxJar',
               logo: 'TaxJar_main-logo.svg',
               icon: 'taxjar.svg',
               link: 'https://www.taxjar.com',
               linkTitle: 'Website',
               rightButtonLabel: this.otherApiIntegration.isEditTaxJar ? 'Save' : 'Add',
               rightButtonFunc: this.saveTaxJar,
               leftButtonFunc: this.cancelTaxJar,
               disabled: false,
               readonly: false,
               flexInputs: [],
               inputs: [
                  {
                     name: 'apiToken',
                     label: 'API Token',
                     value: this.apiToken,
                     class: 'P-padding-100',
                     type: 'text',
                     title: 'API Token'
                  },
               ],
               isValid: this.otherApiIntegration.taxJar.isValid,
               info: "TaxJar API Token is a unique identifier provided by TaxJar, a service that helps businesses automate their sales tax calculations, reporting, and filing. This token acts as an authentication key, allowing users to access TaxJar's API (Application Programming Interface) to integrate their software or systems with TaxJar's tax calculation and reporting services. Developers or businesses use this token to make authenticated requests to the TaxJar API, enabling them to retrieve tax rates, calculate taxes for transactions, and perform other tax-related operations programmatically."
            },
            // {
            //    title: 'Google Key',
            //    logo: 'google.png',
            //    icon: 'google-icon.png',
            //    link: '',
            //    linkTitle: 'Website',
            //    rightButtonLabel: this.otherApiIntegration.isEditTaxJar ? 'Save' : 'Add',
            //    rightButtonFunc: this.saveGoogleMap,
            //    leftButtonFunc: this.cancelGoogleMap,
            //    disabled: false,
            //    readonly: false,
            //    flexInputs: [],
            //    inputs: [
            //       {
            //          name: 'googleKey',
            //          label: 'Google Map Key*',
            //          value: this.googleKey,
            //          class: 'P-padding-100',
            //          type: 'text'
            //       },
            //    ]
            // },
         ]
      },
      ivrToken: {
         get() {
            return this.otherApiIntegration.ivr.ivrToken;
         },
         set(value) {
            this.setIvrToken(value);
         },
      },
      ringToken: {
         get() {
            return this.otherApiIntegration.ring.ringToken;
         },
         set(value) {
            this.setRingToken(value);
         },
      },
      //Checkeeper
      checkToken: {
         get() {
            return this.otherApiIntegration.check.checkToken;
         },
         set(checkToken) {
            this.setCheckData({ checkToken });
         },
      },
      checkSecretKey: {
         get() {
            return this.otherApiIntegration.check.secretKey;
         },
         set(secretKey) {
            this.setCheckData({ secretKey });
         },
      },
      checkBankAccount: {
         get() {
            return this.otherApiIntegration.check.bankAccount;
         },
         set(bankAccount) {
            this.setCheckData({ bankAccount });
         },
      },
      checkTemplateName: {
         get() {
            return this.otherApiIntegration.check.templateName;
         },
         set(templateName) {
            this.setCheckData({ templateName });
         },
      },
      checkCompanyAddress: {
         get() {
            return this.otherApiIntegration.check.companyAddress;
         },
         set(companyAddress) {
            this.setCheckData({ companyAddress });
         },
      },
      checkCompanyName: {
         get() {
            return this.otherApiIntegration.check.companyName;
         },
         set(companyName) {
            this.setCheckData({ companyName });
         },
      },
      checkRoutingNumber: {
         get() {
            return this.otherApiIntegration.check.routingNumber;
         },
         set(routingNumber) {
            this.setCheckData({ routingNumber });
         },
      },
      checkSignatureImage: {
         get() {
            return this.otherApiIntegration.check.signatureImage;
         },
         set(signatureImage) {
            this.setCheckData({ signatureImage });
         },
      },
      //////////
      smartStreetKey: {
         get() {
            return this.otherApiIntegration.smartStreet.smartStreetKey;
         },
         set(value) {
            this.setSmartStreet(value);
         },
      },
      smartyStreetAuthId: {
         get() {
            return this.otherApiIntegration.smartStreet.smartyStreetAuthId;
         },
         set(value) {
            this.setSmartyStreetAuthId(value);
         },
      },
      smartyStreetAuthToken: {
         get() {
            return this.otherApiIntegration.smartStreet.smartyStreetAuthToken;
         },
         set(value) {
            this.setSmartyStreetAuthToken(value);
         },
      },
      postalMethodsKey: {
         get() {
            return this.otherApiIntegration.postalMethods.postalMethodsKey;
         },
         set(value) {
            this.setPostalMethod(value);
         },
      },
      apiToken: {
         get() {
            return this.otherApiIntegration.taxJar.apiToken;
         },
         set(value) {
            this.setTaxJarApiToken(value);
         },
      },
      // googleKey: {
      //    get() {
      //       // get vuex state
      //       return
      //    },
      //    set(value) {
      //       // set vuex mutation
      //    },
      // },

   },

   watch: {
      async accordion(newVal) {
         this.showError = true
         this.updateBox()

         if (newVal === undefined && this.addProviderModal.providerId) {
            await this.getOtherApiIntegration(
               this.addProviderModal.providerId
            ).then((data) => {
               this.fetchData(data);
            })
         }
      },

      ivrToken(newVal) {
         if (newVal && this.otherApiIntegration.isEditIvr) this.setIsEditIvr()
      },

      ringToken(newVal) {
         if (newVal && this.otherApiIntegration.isEditRing) this.setIsEditRing()
      },

      checkToken(newVal) {
         if (newVal && this.otherApiIntegration.isEditCheck) this.setIsEditCheck()
      },

      smartStreetKey(newVal) {
         if (newVal && this.otherApiIntegration.isEditSmartStreet) this.seIisEditSmartStreet()
      },

      // checkSignatureImage(signature) {
      //    if (signature) {
      //       this.$refs.signaturePad[0].signatureData.src = signature
      //    }
      // },

      postalMethodsKey(newVal) {
         if (newVal && this.otherApiIntegration.isEditPostalMethods) this.setIsEditPostalMethods()
      },

      apiToken(newVal) {
         if (newVal && this.otherApiIntegration.isEditTaxJar) this.setIsEditTaxJar()
      },

   },

   async created() {
      if (this.isOpenCompanySettings) {
         await this.getCompanyAddresses(this.companyId)
      }

      if (this.isOpenProviderModal) {
         await this.getCompanyAddresses(this.providerId)
      }

   },

   async mounted() {
      this.accordion = null
      if (this.ivrToken) { this.setIsEditIvr() }
      if (this.ringToken) { this.setIsEditRing() }
      if (this.checkToken ||
         this.checkSecretKey ||
         this.checkBankAccount ||
         this.checkTemplateName ||
         this.checkCompanyAddress ||
         this.checkCompanyName ||
         this.checkRoutingNumber >= 0 ||
         this.checkSignatureImage
      ) { this.setIsEditCheck() }
      if (this.smartStreetKey) { this.seIisEditSmartStreet() }
      if (this.postalMethodsKey) { this.setIsEditPostalMethods() }
      if (this.apiToken) { this.setIsEditTaxJar() }
      if (this.addProviderModal.providerId) {
         await this.getOtherApiIntegration(
            this.addProviderModal.providerId
         ).then((data) => {
            delete data.twilio
            this.fetchData(data);
         })
      }

      this.showError = true
   },

   methods: {
      ...mapActions({
         getCompanyAddresses: 'companyAddressesAdmin/getCompanyAddresses',
      }),

      onInputCheckPhoneNumber(phone, obj) {
         this.validPhoneNumber = !obj.isValid;
         let str = obj.number.input.replace(/[^A-Z0-9]/ig, '')
         this.isValidateNumber = !Number(str)
      },

      clearCheckSignature() {
         if (this.$refs.signaturePad) {
            this.$refs.signaturePad[0].clearSignature()
         }
      },

      openQrLink() {
         window.open('/sign', '_blank');
         if (this.$refs.signaturePad) {
            this.$refs.signaturePad[0].clearSignature()
         }
      },

      async signViaMobileFunc() {
         if (this.checkeeperPhone && !this.isValidateNumber && !this.validPhoneNumber) {
            const response = await this.signViaMobile({
               providerId: this.addProviderModal.providerId,
               phone: this.checkeeperPhone
            })

            if (response) {
               this.createToastMessage(response.data)
            }

         }
      },

      createToastMessage(response) {
         const toastOptions = {
            msg: response.message,
            position: "toast-bottom-right",
            defaultTimeout: 500
         }

         if (response.status) {
            toastOptions.type = "success"
            toastOptions.style = {
               backgroundColor: "#01B2B8",
               width: "max-content",
               textTransform: "capitalize",
               opacity: '1'
            }

            this.$toastr.Add(toastOptions);
         } else {
            toastOptions.type = "error",
               toastOptions.style = {
                  backgroundColor: "red",
                  width: "max-content",
                  textTransform: "capitalize",
                  opacity: '1'
               }

            this.$toastr.Add(toastOptions);
         }
      },

      async updateData() {
         this.showError = false
         this.toSaveData(this.addProviderModal.providerId);
         await this.updateOtherApiIntegration(this.addProviderModal.providerId);
      },

      async updateBox() {
         switch (this.accordion) {
            case 0: {
               this.$v.$reset();
               // if (!this.$v.ivrToken.$error && !this.validationSpaces.ivrToken) {
                  this.saveIvrDataMutation(this.otherApiIntegration.ivr);
               // } else {
               //    this.accordion = [0];
               // }
               break;
            }
            case 1: {
               this.$v.$reset();
               // if (!this.$v.ringToken.$error && !this.validationSpaces.ringToken) {
                  this.saveRingData(this.otherApiIntegration.ring);
               // } else {
               //    this.accordion = [1];
               // }
               break;
            }
            case 2: {
               this.$v.$reset();
               this.saveCheckData(this.otherApiIntegration.check);
               break;
            }
            case 3: {
               // this.$v.$reset();
               // if (
               //    !this.$v.smartStreetKey.$error &&
               //    !this.validationSpaces.smartStreetKey
               // ) {
               this.saveSmartStreetData(this.otherApiIntegration.smartStreet);
               // } else {
               //    this.accordion = [3];
               // }
               break;
            }
            case 4: {
               // this.$v.$reset();
               // if (
               //    !this.$v.postalMethodsKey.$error &&
               //    !this.validationSpaces.postalMethodsKey
               // ) {
               this.savePostalMethodMutation(this.otherApiIntegration.postalMethods);
               // } else {
               //    this.accordion = [4];
               // }
               break;
            }
            case 5: {
               // this.$v.$reset();
               // if (!this.$v.apiToken.$error && !this.validationSpaces.apiToken) {
               this.saveTaxJarData(this.otherApiIntegration.taxJar);
               // } else {
               //    this.accordion = [5];
               // }
               break;
            }
            // case 6: {
            //    this.$v.$reset();
            // }

            default: {
            }
         }
      },

      //IVR
      async saveIvrData() {
         this.validateIvrData();
         if (!this.$v.ivrToken.$error && !this.validationSpaces.ivrToken) {
            this.saveIvrDataMutation(this.otherApiIntegration.ivr);
            await this.updateData();
            this.accordion = [];
         }
      },

      cancelIvrData() {
         this.$v.ivrToken.$reset()
         this.cancelIvrDataMutation();
         this.validationSpaces.ivrToken = false;
         this.accordion = [];
      },

      //Ring Central
      async saveRingCentralData() {
         this.validateRingCentralData();
         if (!this.$v.ringToken.$error && !this.validationSpaces.ringToken) {
            this.saveRingData(this.otherApiIntegration.ring);
            await this.updateData();
            this.accordion = [];
         }
      },

      cancelRingCentralData() {
         this.$v.ringToken.$reset()
         this.cancelRingData();
         this.validationSpaces.ringToken = false;
         this.accordion = [];
      },

      //Checkeeper
      async saveCheckeeperData() {
         const { isEmpty, data } = this.$refs.signaturePad[0].saveSignature();
         if (!isEmpty) {
            this.checkSignatureImage = data
         }
         this.saveCheckData(this.otherApiIntegration.check);
         await this.updateData();
         this.accordion = [];
      },

      cancelCheckeeperData() {
         this.cancelCheckData();
         this.showPhoneError = false;
         this.validPhoneNumber = false;
         this.isValidateNumber = false;
         this.checkeeperPhone = '';
         this.validationSpaces.checkToken = false;
         this.validationSpaces.secretKey = false;
         this.accordion = [];
      },

      //SmartStreet
      async saveSmartStreet() {
         // this.validateSmartStreetData();
         // if (
         //    !this.$v.smartStreetKey.$error &&
         //    !this.validationSpaces.smartStreetKey
         // ) {
            this.saveSmartStreetData(this.otherApiIntegration.smartStreet);
            await this.updateData();
            this.accordion = [];
         // }
      },

      cancelSmartStreet() {
         // this.$v.smartStreetKey.$reset()
         this.cancelSmartStreetData();
         this.accordion = [];
         // this.validationSpaces.smartStreetKey = false;
      },

      //PostalMehtod
      async savePostalMethod() {
         // this.validatePostalMethodData();
         // if (
         //    !this.$v.postalMethodsKey.$error &&
         //    !this.validationSpaces.postalMethodsKey
         // ) {
            this.savePostalMethodMutation(this.otherApiIntegration.postalMethods);
            await this.updateData();
            this.accordion = [];
         // }
      },

      cancelPostalMethod() {
         // this.$v.postalMethodsKey.$reset()
         this.cancelPostalMethodMutation();
         this.accordion = [];
         // this.validationSpaces.postalMethodsKey = false;
      },

      //TaxJar
      async saveTaxJar() {
         // this.validateTaxJar();
         // if (!this.$v.apiToken.$error && !this.validationSpaces.apiToken) {
            this.saveTaxJarData(this.otherApiIntegration.taxJar);
            await this.updateData();
            this.accordion = [];
            // }
      },

      cancelTaxJar() {
         // this.$v.apiToken.$reset()
         this.cancelTaxJarMutation();
         this.accordion = [];
         // this.validationSpaces.apiToken = false;
      },

      // Google Map
      saveGoogleMap() { },
      cancelGoogleMap() {
         this.accordion = [];
      },


      changeInputValue(newValue, apiName) {
         this[apiName] = newValue
      },

      /**************************/

      validateIvrData() {
         this.$v.ivrToken.$touch();
      },
      // validateSmartStreetData() {
      //    this.$v.smartStreetKey.$touch();
      // },
      // validatePostalMethodData() {
      //    this.$v.postalMethodsKey.$touch();
      // },
      validateRingCentralData() {
         this.$v.ringToken.$touch();
      },
      // validateTaxJar() {
      //    this.$v.apiToken.$touch();
      // },
      validateSpace() {
         const [fileName] = arguments;
         if (this.$v[fileName]) {
            this.$v[fileName].$touch();
         }
         this.validationSpaces[fileName] = this[fileName].split(" ").length > 1;
      },
   },
};