<template>
  <div>
    <TitleComponent title-text="Conversation API" />
    
    <div>
      <v-expansion-panels v-model="activeTab" class="G-accordion">
        <v-expansion-panel v-for="(item, index) in conversationApis" :key="index">
          <v-expansion-panel-header>
            <div class="G-accordion-header G-flex G-align-center G-justify-between">
              <div class="G-flex G-align-center">
                <img class="P-accordion-img" :src="require('@/assets/images/' + item.img)" width="36" />
                <p class="secondary--text mb-0">{{ item.title }}</p>
              </div>
              <div v-if="showError && (
                  (index === 1 && telegramAccessToken) || 
                  (index === 2 && (sid || twilioAuthToken || fromNumber))
                )">
                <div v-if="isValid && index === 1" class="G-flex G-align-center me-6">
                  <span class="secondary--text status me-2 text-uppercase">Active</span>
                  <v-icon class="success--text" medium>mdi-checkbox-marked-circle-outline</v-icon>
                </div>
                <div v-if="isValidTwilio && index === 2 && sid && twilioAuthToken && fromNumber" class="G-flex G-align-center me-6">
                  <span class="status me-2 text-uppercase secondary--text">Active</span>
                  <v-icon class="success--text" medium>mdi-checkbox-marked-circle-outline</v-icon>
                </div>

                <div v-if="
                  (isValid === false && index === 1 && telegramAccessToken) || 
                  (isValidTwilio === false && index === 2 && (sid || twilioAuthToken || fromNumber)) ||
                  (isValidTwilio === true && index === 2 && (sid || twilioAuthToken) && !fromNumber)
                " class="G-flex G-align-center me-6">
                  <span class="secondary--text status me-2 text-uppercase">Failed</span>
                  <v-icon class="error--text" medium>mdi-cancel</v-icon>
                </div>
              </div>
            </div>
            <template v-slot:actions>
              <span class="mdi mdi-plus neutral--text P-accordion-plus" />
              <span class="mdi mdi-minus neutral--text P-accordion-minus" />
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              <v-alert 
                v-if="showError && (
                  (isValid === false && index === 1 && telegramAccessToken) || 
                  (isValidTwilio === false && index === 2 && (sid || twilioAuthToken || fromNumber)) ||
                  (isValidTwilio === true && index === 2 && (sid || twilioAuthToken) && !fromNumber)
                )" 
                type="error"
              >
                The provided token is invalid
              </v-alert>
              <p>{{ item.info }}</p>
              <div v-if="index === 0 || index === 1" class="G-flex G-align-center P-input-block">
                <div class="P-title G-flex G-align-center">
                  <h4>Access token</h4>
                  <v-tooltip bottom color="secondary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="secondary--text ms-3">mdi-information-outline</v-icon>
                    </template>
                    <p class="P-tooltip-content mb-0">{{ item.tokenInfo }}</p>
                  </v-tooltip>
                </div>
                <div class="P-padding-50">
                  <!-- For viber -->
                  <ott-input
                    v-if="index === 0" 
                    label="Please provide your access token" 
                    v-model="viberAuthToken"
                  />
                  <!-- For telegram -->
                  <ott-input
                    v-if="index === 1"
                    v-model="telegramAccessToken"
                    label="Please provide your access token" 
                  />
                </div>
              </div>

              <!-- Inputs for Twilio -->
              <div v-if="index === 2">
                <div class="G-flex G-align-center P-input-block">
                  <div class="P-title G-flex G-align-center">
                    <h4>Account SID</h4>
                  </div>
                  <div class="P-padding-50">
                    <ott-input
                      label="Please provide your SID" 
                      v-model="sid"
                    />
                  </div>
                </div>
                <div class="G-flex G-align-center P-input-block">
                  <div class="P-title G-flex G-align-center">
                    <h4>Auth Token</h4>
                  </div>
                  <div class="P-padding-50">
                    <ott-input
                      label="Please provide your auth token" 
                      v-model="twilioAuthToken"
                    />
                  </div>
                </div>
                <div class="G-flex G-align-center P-input-block">
                  <div class="P-title G-flex G-align-center">
                    <h4>My Twilio phone number</h4>
                  </div>
                  <div class="P-padding-50">
                    <ott-input
                      label="Please provide your number" 
                      v-model="fromNumber"
                    />
                  </div>
                </div>
              </div>
              
              <!-- For viber callback verification -->
              <div class="G-flex G-align-center P-input-block" v-if="index === 0">
                <div class="P-title G-flex G-align-center">
                  <h4>Callback verification</h4>
                  <v-tooltip bottom color="secondary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" class="secondary--text ms-3">mdi-information-outline</v-icon>
                    </template>
                    <p class="P-tooltip-content mb-0">{{ item.callbackVerificationInfo }}</p>
                  </v-tooltip>
                </div>
                <div class="P-padding-50">
                  <ott-switch
                    v-model="callbackVerification"
                  />
                </div>
              </div>
              

              <div class="G-flex G-justify-between G-align-end">
                <save-reset-buttons
                  :is-loading="isLoading"
                  :left-click-func="() => item.closeTab()"
                  :right-click-func="() => item.saveData()"
                />

                <v-tooltip v-if="item.link" top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <a :href="item.link" class="G-align-center G-justify-center" target="_blank">
                        <img :src="require('@/assets/images/' + item.img)" alt="svg" width="38" />
                      </a>
                    </div>
                  </template>
                  <span>Website</span>
                </v-tooltip>
              </div>
              
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import SaveResetButtons from "@/components/SaveResetButtons";

import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "ConversationApiAdmin",
  components: { TitleComponent, OttInput, OttSwitch, SaveResetButtons },
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      viberAuthToken: '',
      callbackVerification: true,
      showError: false,
      telegramAccessToken: '',
      sid: '',
      twilioAuthToken: '',
      fromNumber: '',
      activeTab: null,
      isLoading: false
    }
  },
  watch: {
    activeTab() {
      this.showError = true
    }
  },
  computed: {
    ...mapState({
      conversationData: state => state.conversationApiModule.conversationData,
      isValid: state => state.conversationApiModule.isValid,
      isValidTwilio: state => state.conversationApiModule.isValidTwilio
    }),
    conversationApis() {
      return [
        {
          title: 'Viber Bot',
          img: 'viber.png',
          info: "Add your Access token in order to configure Viber Bot to work with your Conversation API App. Note that to be able to send and receive Viber Bot messages through the Conversation API, you must first obtain the Access token from Viber which you do by creating an account at Viber. You can instruct Conversation API to verify the signature of the callbacks and thus guarantee all callbacks are genuine by enabling the switch below.",
          tokenInfo: "An access token identifies the Viber Bot and is used by the Conversation API app to authenticate when sending the messages.",
          saveData: this.saveConversation,
          closeTab: this.closeTab,
          callbackVerificationInfo: "Callback verification is a feature that let the Conversation API verify that the Viber callbacks are genuine."
        },
        {
          title: 'Telegram',
          img: 'telegram.png',
          info: "Add your Access token in order to configure Telegram to work with your Conversation API App. Note that to be able to send and receive Telegram messages through the Conversation API, you must first obtain the 'Access token' from Telegram which you do by creating an account of Telegram.",
          tokenInfo: "An access token identifies the Telegram and is used by the Conversation API app to authenticate when sending the messages.",
          closeTab: this.closeTab,
          saveData: this.saveConversation,
        },
        {
          title: 'Twilio',
          img: 'twilio.png',
          info: "An Authentication Token (auth token) is a piece of information that verifies the identity of a user to a website, server, or anyone requesting verification of the user’s identity.",
          tokenInfo: "The authentication token acts as a password to authenticate your API requests. It provides secure access to your Twilio account and API functionality. It should be kept confidential, similar to a password, to ensure the security of your Twilio account.",
          sidInfo: "This is a unique identifier for the Twilio service you're using. It's like a username for your Twilio account, allowing Twilio to identify and authenticate your specific service or application.",
          fromNumberInfo: "This is the phone number that will be used as the sender when sending messages or making calls through Twilio. It represents the number that recipients will see when they receive messages or calls from your application. It needs to be a valid number associated with your Twilio account.",
          closeTab: this.closeTab,
          saveData: this.saveConversation,
          link: "https://www.twilio.com/login"
        }
      ]
    }
  },
  async mounted() {
    try {
      const response = await this.getConversation({ id: this.id })
  
      if (response) {
        this.telegramAccessToken = response.telegram?.authToken
        this.sid = response.twilio?.sId
        this.twilioAuthToken = response.twilio?.authToken
        this.fromNumber = response.twilio?.fromNumber
      }
    } catch (error) {
      console.error('error', error)
    }

    this.showError = true
  },
  methods: {
    ...mapActions({
      conversationUpdate: "conversationApiModule/conversationUpdate",
      getConversation: "conversationApiModule/getConversation"
    }),
    ...mapMutations({
      setData: "conversationApiModule/setData"
    }),
    async saveConversation() {
      this.showError = false
      this.isLoading = true
      let data = {}

      switch (this.activeTab) {
        case 0:
            data = {
              viber: {
                authToken: this.viberAuthToken
              }
            }
            break
        case 1:
            data = {
              telegram: {
                authToken: this.telegramAccessToken
              }
            }
          break
        case 2:
          data = {
            twilio: {
              authToken: this.twilioAuthToken,
              sId: this.sid,
              fromNumber: this.fromNumber
            }
          }
          break
      }

      await this.conversationUpdate({ id: this.id, data: data }).then(data => {
        if (data) {
          this.isLoading = false
        }
      }).finally(() => this.isLoading = false)

      this.closeTab();
    },
    closeTab() {
      this.activeTab = null
    },
  },
}
</script>
<style scoped lang="scss">
@import "./src/assets/scss/variables";
.P-modal-component-header {
  min-height: auto;
}
.P-input-block {
  .P-title {
    width: 28%;
  }
}
::v-deep .v-input--selection-controls {
  margin-top: 0 !important;
}
.v-input.theme--light.v-input--selection-controls.v-input--switch.v-input--switch--inset {
  margin-top: 0 !important;
}

.P-accordion-img {
  margin-right: 20px;
  border-radius: 8px;
}

.P-tooltip-content {
  max-width: 400px !important;
  width: 100% !important;
}

.v-application.theme--dark .P-tooltip-content {
  color: $dark_light;
}

.G-accordion-header {
  .status {
    font-weight: 500;
  }
}
</style>