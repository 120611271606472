
export default {
  data() {
    return {
      apiIntegrationsData: [],
      accordion: null,
      showAuthorizeErrors: false,
      showPayPalErrors: false,
      showStripErrors: false,
      showCloverErrors: false,
      showSquareErrors: false,
      validationSpaces: {
        authorizeApiLoginId: false,
        authorizeTransactionKey: false,
        authorizeCurrentSignatureKey: false,
        payPalUsername: false,
        payPalPassword: false,
        signature: false,
        stripeSecretKey: false,
        cloverMerchantId: false,
        cloverSecretKey: false,
        // squareApplicationId: false,
        // squareLocationId: false,
        // squareSecretKey: false,
        // publicKey: false,
      },
      autopaySelectValue: null,
      cardsSelectValue: null,
      bankSelectValue: null,
      showWarningModal: false,
      warningModalIsLoading: false,
      lastPaymentSelectType: null,
      showAlert: false
    };
  },
  computed: {
    autoPayFee() {
      return this.merchant.autoPayFee;
    },

    cardsFee() {
      return this.merchant.cardsFee;
    },

    bankFee() {
      return this.merchant.bankFee;
    },

    isAutopay: {
      get() {
        return this.merchant.isAutopay;
      },
      set(value) {
        this.setIsAutopay(value);
      },
    },

    isCards: {
      get() {
        return this.merchant.isCards;
      },
      set(value) {
        this.setIsCards(value);
      },
    },

    isBank: {
      get() {
        return this.merchant.isBank;
      },
      set(value) {
        this.setIsBank(value);
      },
    },

    isAutoPayCustom: {
      get() {
        return this.autoPayFee.enabled;
      },
      set(val) {
        this.setDataSecondLevels({
          autoPayFee: { enabled: val !== null ? true : false }
        })
      }
    },

    autoPayFixed: {
      get() {
        return this.autoPayFee.fixed;
      },
      set(fixed) {
        this.setDataSecondLevels({
          autoPayFee: { fixed }
        })
      }
    },

    autoPayPercent: {
      get() {
        return this.autoPayFee.percent;
      },
      set(percent) {
        this.setDataSecondLevels({
          autoPayFee: { percent }
        })
      }
    },

    isCardsCustom: {
      get() {
        return this.cardsFee.enabled;
      },
      set(val) {
        this.setDataSecondLevels({
          cardsFee: { enabled: val !== null ? true : false }
        })
      }
    },

    cardsFixed: {
      get() {
        return this.cardsFee.fixed;
      },
      set(fixed) {
        this.setDataSecondLevels({
          cardsFee: { fixed }
        })
      }
    },

    cardsPercent: {
      get() {
        return this.cardsFee.percent;
      },
      set(percent) {
        this.setDataSecondLevels({
          cardsFee: { percent }
        })
      }
    },

    isBankCustom: {
      get() {
        return this.bankFee.enabled
      },
      set(val) {
        this.setDataSecondLevels({
          bankFee: { enabled: val !== null ? true : false }
        })
      }
    },

    bankFixed: {
      get() {
        return this.bankFee.fixed
      },
      set(fixed) {
        this.setDataSecondLevels({
          bankFee: { fixed }
        })
      }
    },

    bankPercent: {
      get() {
        return this.bankFee.percent
      },
      set(percent) {
        this.setDataSecondLevels({
          bankFee: { percent }
        })
      }
    },

    // Authorize
    authorizeData() {
      return this.merchant.authorizeData;
    },
    authorizeDataCustom() {
      return {
        apiLoginId: this.authorizeApiLoginIdVal,
        transactionKey: this.authorizeTransactionKeyVal,
        currentSignatureKey: this.authorizeCurrentSignatureKeyVal,
        isValid: this.isValidAuthorize
      }
    },
    authorizeApiLoginId: {
      get() {
        return this.authorizeData.apiLoginId;
      },
      set(value) {
        this.setApiLoginId(value)
      },
    },
    authorizeTransactionKey: {
      get() {
        return this.authorizeData.transactionKey;
      },
      set(value) {
        this.setTransactionKey(value)
      },
    },
    authorizeCurrentSignatureKey: {
      get() {
        return this.authorizeData.currentSignatureKey;
      },
      set(value) {
        this.setCurrentSignatureKey(value)
      },
    },
    isValidAuthorize: {
      get() {
        return this.authorizeData.isValid;
      },
      set(isValid) {
        this.setDataSecondLevels({
          authorizeData: { isValid }
        })
      },
    },
    authorizeApiLoginIdVal() {
      return this.apiIntegrationsData[0].flexInputs[0].value.trim()
    },
    authorizeTransactionKeyVal() {
      return this.apiIntegrationsData[0].flexInputs[1].value.trim()
    },
    authorizeCurrentSignatureKeyVal() {
      return this.apiIntegrationsData[0].inputs[0].value.trim()
    },

    //PayPal
    payPalData() {
      return this.merchant.payPalData;
    },
    payPalDataCustom() {
      return {
        username: this.payPalUsernameVal,
        password: this.payPalPasswordVal,
        signature: this.payPalSignatureVal,
        isValid: this.isValidPayPal
      }
    },
    payPalUsername: {
      get() {
        return this.payPalData.username;
      },
      set(value) {
        this.setUsername(value)
      },
    },
    payPalPassword: {
      get() {
        return this.payPalData.password;
      },
      set(value) {
        this.setPassword(value)
      },
    },
    payPalSignature: {
      get() {
        return this.payPalData.signature;
      },
      set(value) {
        this.setSignature(value)
      },
    },
    isValidPayPal: {
      get() {
        return this.payPalData.isValid;
      },
      set(isValid) {
        this.setDataSecondLevels({
          payPalData: { isValid }
        })
      },
    },
    payPalUsernameVal() {
      return this.apiIntegrationsData[1].flexInputs[0].value.trim()
    },
    payPalPasswordVal() {
      return this.apiIntegrationsData[1].flexInputs[1].value.trim()
    },
    payPalSignatureVal() {
      return this.apiIntegrationsData[1].inputs[0].value.trim()
    },

    // Stripe
    stripeData() {
      return this.merchant.stripeData;
    },
    stripeDataCustom() {
      return {
        publicKey: this.stripePublicKeyVal,
        secretKey: this.stripeSecretKeyVal,
        isValid: this.isValidStripe
      }
    },
    stripePublicKey: {
      get() {
        return this.stripeData.publicKey;
      },
      set(value) {
        this.setPublicKey(value)
      },
    },
    stripeSecretKey: {
      get() {
        return this.stripeData.secretKey;
      },
      set(value) {
        this.setSecretKey(value)
      },
    },
    isValidStripe: {
      get() {
        return this.stripeData.isValid;
      },
      set(isValid) {
        this.setDataSecondLevels({
          stripeData: { isValid }
        })
      },
    },
    stripeSecretKeyVal() {
      return this.apiIntegrationsData[2].inputs[0].value.trim()
    },
    stripePublicKeyVal() {
      return this.apiIntegrationsData[2].inputs[1].value.trim()
    },

    // Clover
    cloverData() {
      return this.merchant.cloverData;
    },
    cloverDataCustom() {
      return {
        merchantId: this.cloverMerchantIdVal,
        secretKey: this.cloverSecretKeyVal,
        isValid: this.isValidClover
      }
    },
    cloverMerchantId: {
      get() {
        return this.cloverData.merchantId;
      },
      set(value) {
        this.setCloverMerchantId(value)
      },
    },
    cloverSecretKey: {
      get() {
        return this.cloverData.secretKey;
      },
      set(value) {
        this.setCloverSecretKey(value)
      },
    },
    isValidClover: {
      get() {
        return this.cloverData.isValid;
      },
      set(isValid) {
        this.setDataSecondLevels({
          cloverData: { isValid }
        })
      },
    },
    cloverMerchantIdVal() {
      return this.apiIntegrationsData[3].inputs[0].value.trim()
    },
    cloverSecretKeyVal() {
      return this.apiIntegrationsData[3].inputs[1].value.trim()
    },

    // Square
    squareData() {
      return this.merchant.squareData;
    },
    squareDataCustom() {
      return {
        applicationId: this.squareApplicationIdVal,
        locationId: this.squareLocationIdVal,
        secretKey: this.squareSecretKeyVal,
        isValidApplicationId: this.squareData.isValidApplicationId,
        isValid: this.isValidSquare
      }
    },

    squareApplicationId: {
      get() {
        return this.squareData.applicationId;
      },
      set(value){
        this.setSquareApplicationId(value)
      }
    },
    squareLocationId: {
      get() {
        return this.squareData.locationId;
      },
      set(value){
        this.setSquareLocationId(value)
      }
    },
    squareSecretKey: {
      get() {
        return this.squareData.secretKey;
      },
      set(value){
        this.setSquareSecretKey(value)
      }
    },
    isValidSquare: {
      get() {
        return this.squareData.isValid;
      },
      set(isValid) {
        this.setDataSecondLevels({
          squareData: { isValid }
        })
      },
    },
    squareApplicationIdVal() {
      return this.apiIntegrationsData[4].inputs[0].value.trim()
    },
    squareSecretKeyVal() {
      return this.apiIntegrationsData[4].inputs[1].value.trim()
    },
    squareLocationIdVal() {
      return this.apiIntegrationsData[4].inputs[2].value.trim()
    },
  },

  watch: {
    async accordion(newVal) {
      this.showAlert = true
      this.updateBox()
      if (newVal === undefined && this.addProviderModal.providerId) {
        await this.getPaymentGateway(this.addProviderModal.providerId)
          .then((data) => {
            this.fetchData(data);
            this.getMerchantListDataFromUpdater();
          });
      } else {
        this.showAuthorizeErrors = false
        this.showPayPalErrors = false
        this.showStripErrors = false
        this.showCloverErrors = false
        this.showSquareErrors = false
      }
    },

    'selectedData.selectAutoPay'(newVal, oldVal) {
      this.autopaySelectValue = oldVal
    },

    'selectedData.selectCards'(newVal, oldVal) {
      this.cardsSelectValue = oldVal
    },

    'selectedData.selectBank'(newVal, oldVal) {
      this.bankSelectValue = oldVal
    }
  },

  async created() {
    if (this.authorizeApiLoginId) {
      this.setIsEditAuthorize();
    }
    if (this.payPalyUsername) {
      this.setIsEditPayPal();
    }
    if (this.stripePublicKey) {
      this.setIsEditStripe();
    }
    if (this.addProviderModal.providerId) {
      await this.getPaymentGatewayData()

      if (this.selectedData.selectAutoPay)
        this.autopaySelectValue = this.selectedData.selectAutoPay

      if (this.selectedData.selectCards)
        this.cardsSelectValue = this.selectedData.selectCards

      if (this.selectedData.selectBank)
        this.bankSelectValue = this.selectedData.selectBank
    }
  },

  mounted() {
    this.showAlert = true;
  },

  methods: {
    async getPaymentGatewayData() {
      await this.getPaymentSelectList(this.addProviderModal.providerId);
      await this.getPaymentGateway(this.addProviderModal.providerId)
        .then((data) => {
          this.fetchData(data);
          this.getMerchantListDataFromUpdater();

          this.apiIntegrationsData = [
            {
              name: 'authorize',
              title: 'Authorize',
              logo: 'Authorize.svg',
              icon: 'authorize_mini.svg',
              link: 'https://www.authorize.net',
              linkTitle: 'Website',
              rightButtonLabel: this.merchant.isEditAuthorize ? 'Save' : 'Add',
              rightButtonFunc: this.saveAuthorize,
              leftButtonFunc: this.cancelAuthorize,
              disabled: false,
              readonly: false,
              flexInputs: [
                {
                  name: 'authorizeApiLoginId',
                  label: 'API Login ID',
                  value: this.authorizeApiLoginId,
                  class: 'P-padding-100',
                  type: 'text'
                },
                {
                  name: 'authorizeTransactionKey',
                  label: 'Transaction Key',
                  value: this.authorizeTransactionKey,
                  class: 'P-padding-100',
                  type: 'text'
                }
              ],
              inputs: [
                {
                  name: 'authorizeCurrentSignatureKey',
                  label: 'Current Signature Key',
                  value: this.authorizeCurrentSignatureKey,
                  class: 'P-padding-100',
                  type: 'text'
                }
              ],
              isValid: this.isValidAuthorize
            },
            {
              name: 'paypal',
              title: 'PayPal',
              logo: 'Paypal.svg',
              icon: 'paypal_mini.svg',
              link: 'https://www.paypal.com',
              linkTitle: 'Website',
              rightButtonLabel: this.merchant.isEditPayPal ? 'Save' : 'Add',
              rightButtonFunc: this.savePayPal,
              leftButtonFunc: this.cancelPayPal,
              disabled: true,
              readonly: true,
              flexInputs: [
                {
                  name: 'payPalUsername',
                  label: 'API Username*',
                  value: this.payPalUsername,
                  class: 'P-padding-50',
                  type: 'text'
                },
                {
                  name: 'payPalPassword',
                  label: 'Password*',
                  value: this.payPalPassword,
                  class: 'P-padding-50',
                  type: 'password'
                }
              ],
              inputs: [
                {
                  name: 'payPalSignature',
                  label: 'API signature',
                  value: this.payPalSignature,
                  class: 'P-padding-100',
                  type: 'text'
                }
              ],
              isValid: this.isValidPayPal
            },
            {
              name: 'stripe',
              title: 'Stripe',
              logo: 'stripe.svg',
              icon: 'stripe_mini.svg',
              link: 'https://stripe.com',
              linkTitle: 'Website',
              rightButtonLabel: this.merchant.isEditStripe ? 'Save' : 'Add',
              rightButtonFunc: this.saveStripe,
              leftButtonFunc: this.cancelStripe,
              disabled: false,
              readonly: false,
              flexInputs: [],
              inputs: [
                {
                  name: 'stripeSecretKey',
                  label: 'Secret Key',
                  value: this.stripeSecretKey,
                  class: 'P-padding-100',
                  type: 'text'
                },
                {
                  name: 'stripePublicKey',
                  label: 'Public Key',
                  value: this.stripePublicKey,
                  class: 'P-padding-100',
                  type: 'text'
                }
              ],
              isValid: this.isValidStripe
            },
            {
              name: 'clover',
              title: 'Clover',
              logo: 'clover-logo.png',
              icon: 'clover.png',
              link: 'https://www.clover.com/dashboard/login',
              linkTitle: 'Website',
              rightButtonLabel: this.merchant.isEditClover ? 'Save' : 'Add',
              rightButtonFunc: this.saveClover,
              leftButtonFunc: this.cancelClover,
              disabled: false,
              readonly: false,
              flexInputs: [],
              inputs: [
                {
                  name: 'cloverMerchantId',
                  label: 'Merchant ID',
                  value: this.cloverMerchantId,
                  class: 'P-padding-100',
                  type: 'text'
                },
                {
                  name: 'cloverSecretKey',
                  label: 'Secret Key',
                  value: this.cloverSecretKey,
                  class: 'P-padding-100',
                  type: 'text'
                }
              ],
              isValid: this.isValidClover,
            },
            {
              name: 'square',
              title: 'Square',
              icon: 'square-logo-mini.png',
              link: 'https://squareup.com/login?lang_code=en-us',
              linkTitle: 'Website',
              rightButtonLabel: 'Save',
              rightButtonFunc: this.saveSquare,
              leftButtonFunc: this.cancelSquare,
              disabled: false,
              readonly: false,
              flexInputs: [],
              // info: "Click the button to connect your account to Square and start accepting online payments.",
              inputs: [
                {
                  name: 'squareApplicationId',
                  label: 'Application ID',
                  value: this.squareApplicationId,
                  class: 'P-padding-100',
                  type: 'text'
                },
                {
                  name: 'squareSecretKey',
                  label: 'Access token',
                  value: this.squareSecretKey,
                  class: 'P-padding-100',
                  type: 'text'
                },
                {
                  name: 'squareLocationId',
                  label: 'Location ID',
                  value: this.squareLocationId,
                  class: 'P-padding-100',
                  type: 'text'
                },
              ],
              isValid: this.isValidSquare,
            }
          ]
        });
    },

    openWarningModal(type) {
      this.lastPaymentSelectType = type
      this.showWarningModal = true
    },

    closeWarningModal() {
      switch (this.lastPaymentSelectType) {
        case 'autopay':
          this.selectedData.selectAutoPay = this.autopaySelectValue
          break;
        case 'cards':
          this.selectedData.selectCards = this.cardsSelectValue
          break;
        case 'bank':
          this.selectedData.selectBank = this.bankSelectValue
          break;
      }
      this.lastPaymentSelectType = null;
      this.showWarningModal = false;
    },

    /**
     * change  selected data and remove from  selected  list,
     * selected data
     * **/
    clearData(value) {
      let selectedValueType = arguments[0]["type"];
      switch (selectedValueType) {
        case 1: {
          this.selectedData.selectAutoPay = null;
          break;
        }
        case 2: {
          this.selectedData.selectCards = null;
          break;
        }
        case 3: {
          this.selectedData.selectBank = null;
          break;
        }
      }
      this.clearMerchantList(arguments[0]["0"]);
    },
    getSelectedData(data) {
      this.setSelectedDataFocus(data);
    },
    async selectDataValue(value) {
      let selectedValueType = arguments[0]["type"];
      switch (selectedValueType) {
        case 1: {
          this.selectedData.selectAutoPay = arguments[0]["0"];
          break;
        }
        case 2: {
          this.selectedData.selectCards = arguments[0]["0"];
          break;
        }
        case 3: {
          this.selectedData.selectBank = arguments[0]["0"];
          break;
        }
      }
      await this.updateData(arguments[0]["0"]);
    },
    saveData() {
      this.updateData()
    },
    async updateData() {
      const [value] = arguments;
      this.showAlert = false
      this.toSaveData(this.addProviderModal.providerId);
      await this.updatePaymentGateway(this.addProviderModal.providerId).then(async data => {
        if (data) {
          await this.getPaymentGatewayData();
        }
      })
      this.updateMerchant(value);
    },

    updateBox() {
      switch (this.accordion) {
        case 0: {
          // if (
          //   this.apiIntegrationsData[0].flexInputs[0].value.trim() && // authorizeApiLoginId
          //   this.apiIntegrationsData[0].flexInputs[1].value.trim() && // authorizeTransactionKey
          //   !this.validationSpaces.authorizeApiLoginId &&
          //   !this.validationSpaces.authorizeTransactionKey &&
          //   !this.validationSpaces.authorizeCurrentSignatureKey
          // ) {
          this.saveAuthorizeData(this.authorizeDataCustom);
          // } else {
          //   this.accordion = 0;
          // }
          break;
        }
        case 1: {
          // if (
          //   this.apiIntegrationsData[1].flexInputs[0].value.trim() && // payPalUsername
          //   this.apiIntegrationsData[1].flexInputs[1].value.trim() && // paypalPassword
          //   !this.validationSpaces.payPalUsername &&
          //   !this.validationSpaces.payPalPassword &&
          //   !this.validationSpaces.signature
          // ) {
          this.savePayPalData(this.payPalDataCustom);
          // } else {
          //   this.accordion = 1;
          // }
          break;
        }
        case 2: {
          // if (
          //   this.apiIntegrationsData[2].inputs[0].value.trim() && // stripeSecretKey &&
          //   !this.validationSpaces.stripeSecretKey
          // ) {
          this.saveStripeData(this.stripeDataCustom);
          // } else {
          //   this.accordion = 2;
          // }
          break;
        }
        case 3: {
          this.saveCloverData(this.cloverDataCustom);
        }
        case 4: {
          this.saveSquareData(this.squareDataCustom);
        }
      }
    },

    // Autorize
    async saveAuthorize() {
      // this.showAuthorizeErrors = true

      // if (
      //   this.authorizeApiLoginIdVal.trim() &&
      //   this.authorizeTransactionKeyVal.trim() &&
      //   !this.validationSpaces.authorizeApiLoginId &&
      //   !this.validationSpaces.authorizeTransactionKey &&
      //   !this.validationSpaces.authorizeCurrentSignatureKey
      // ) {
      this.saveAuthorizeData(this.authorizeDataCustom);
      await this.updateData();
      this.accordion = [];
      // }
    },
    cancelAuthorize() {
      this.showAuthorizeErrors = false;
      this.cancelAuthorizeData();
      this.accordion = null;
      this.validationSpaces.authorizeApiLoginId = false;
      this.validationSpaces.authorizeTransactionKey = false;
      this.validationSpaces.authorizeCurrentSignatureKey = false;
    },

    // PayPal
    async savePayPal() {
      // this.showPayPalErrors = true

      // if (
      //   this.payPalUsernameVal.trim() &&
      //   this.payPalPasswordVal.trim() &&
      //   !this.validationSpaces.payPalUsername &&
      //   !this.validationSpaces.payPalPassword &&
      //   !this.validationSpaces.signature
      // ) {
      this.savePayPalData(this.payPalDataCustom);
      await this.updateData();
      this.accordion = [];
      // }
    },
    cancelPayPal() {
      this.showPayPalErrors = false
      this.cancelPayPalData();
      this.accordion = null;
      this.validationSpaces.payPalUsername = false;
      this.validationSpaces.payPalPassword = false;
      this.validationSpaces.signature = false;
    },

    // Stripe
    async saveStripe() {
      // this.showStripErrors = true

      // if (
      //   this.stripeSecretKeyVal.trim() &&
      //   !this.validationSpaces.stripePublicKey &&
      //   !this.validationSpaces.stripeSecretKey
      // ) {
      this.saveStripeData(this.stripeDataCustom);
      await this.updateData();
      this.accordion = [];
      // }
    },
    cancelStripe() {
      this.showStripErrors = false
      this.cancelStripeData();
      this.accordion = null;
      this.validationSpaces.stripeSecretKey = false;
    },

    // Clover
    async saveClover() {
      this.saveCloverData(this.cloverDataCustom);
      await this.updateData();
      this.accordion = [];
    },
    cancelClover() {
      this.showCloverErrors = false
      this.cancelCloverData();
      this.accordion = null;
      this.validationSpaces.cloverSecretKey = false;
      this.validationSpaces.cloverMerchantId = false;
    },

    // Square
    async saveSquare() {
      if (this.squareApplicationIdVal && this.squareLocationIdVal) {
        try {
          const square = await Square.payments(this.squareApplicationIdVal, this.squareLocationIdVal)
          await square.card()
          this.setSquareIsValidApplicationId(true)
        } catch(e) {
          console.log(e, 'e')
          this.setSquareIsValidApplicationId(false)
        }  
      } else {
        this.setSquareIsValidApplicationId(true)
      }

      this.saveSquareData(this.squareDataCustom);
      await this.updateData();
      this.accordion = [];
    },
    cancelSquare() {
      // this.showSquareErrors = false
      // this.cancelSquareData();
      this.accordion = null;
      this.validationSpaces.squareApplicationId = false
      this.validationSpaces.squareLocationId = false
      this.validationSpaces.squareSecretKey = false
    },

    getInpErrors(apiName, value, inputName) {
      switch (apiName) {
        case 'authorize':
          if (
            (this.showAuthorizeErrors && inputName === 'authorizeApiLoginId' && !value.trim()) ||
            (this.showAuthorizeErrors && inputName === 'authorizeTransactionKey' && !value.trim())
          ) {
            return true
          }
          break;

        case 'paypal':
          if (
            (this.showPayPalErros && inputName === 'payPalUsername' && !value.trim()) ||
            (this.showPayPalErros && inputName === 'payPalPassword' && !value.trim())
          ) {
            return true
          }
          break;

        case 'stripe':
          if (this.showStripErrors && inputName === 'stripeSecretKey' && !value.trim()) {
            return true
          }
          break;

        case 'clover':
          if (
            (this.showCloverErrors && inputName === 'cloverMerchantId' && !value.trim()) ||
            (this.showCloverErrors && inputName === 'cloverSecretKey' && !value.trim())
          ) {
            return true
          }
          break;
        case 'square':
          if (
            (this.showSquareErrors && inputName === 'squareApplicationId' && !value.trim()) ||
            (this.showSquareErrors && inputName === 'squareLocationId' && !value.trim()) ||
            (this.showSquareErrors && inputName === 'squareSecretKey' && !value.trim()) 
          ){
            return true
          }
          break;

        default:
          return false;
          break;
      }
    },

    validateSpace(fileName) {
      this.validationSpaces[fileName] = this[fileName].split(" ").length > 1;
    },
  }
}
