export class ContactInfoModel {
    saveData(data, providerId) {
        const body = [];
        if (data.phones.length) {
            body.companyPhones = data.phones.map(item => {
                if (providerId) {
                    return item.id
                } else {
                    return {
                        inUse: !item.inUse,
                        isMain: item.inMain,
                        isSupport: item.isSupport,
                        isMobile: item.isMobile,
                        forInvoice: item.forInvoice,
                        number: item.phone
                    };
                }
            });
        }
        if (data.emails.length) {
            body.companyEmails = data.emails.map(item => {
                if (providerId) {
                    return item.id
                } else {
                    const emailsCurrent = {};
                    emailsCurrent.isMain = item.inMain;
                    emailsCurrent.inUse = !item.inUse;
                    emailsCurrent.isInfo = item.isInfo;
                    emailsCurrent.isSupport = item.isSupport;
                    emailsCurrent.forInvoice = item.forInvoice;
                    emailsCurrent.forSend = item.useForSent && item.useForSent.isForSent? item.useForSent.isForSent: false;
                    emailsCurrent.address = item.emailValue;
                    if (item.useForSent.isForSent) {
                        emailsCurrent.smtp = {
                            server: item.useForSent.smtpServer,
                            port: item.useForSent.smtpPort,
                            username: item.useForSent.username,
                            password: item.useForSent.useForSentPassword,
                            ssl: item.useForSent.useSSL
                        };
                    }
                    return emailsCurrent;
                }
            });
        }
        return body;
    }

    manageEmail(data, providerId, isAddEmail) {

        const emailsCurrent = {};
        if(isAddEmail){
            emailsCurrent.providerId = providerId
        }

        emailsCurrent.isMain = data.inMain;
        emailsCurrent.inUse = !data.inUse;
        emailsCurrent.isInfo = data.isInfo;
        emailsCurrent.isSupport = data.isSupport;
        emailsCurrent.forInvoice = data.forInvoice;

            emailsCurrent.forSend = data.useForSent.isForSent? data.useForSent.isForSent: false
        emailsCurrent.address = data.emailValue;
        if (data.useForSent.isForSent) {
            emailsCurrent.smtp = {
                server: data.useForSent.smtpServer,
                port: data.useForSent.smtpPort,
                username: data.useForSent.username,
                password: data.useForSent.useForSentPassword,
                ssl: data.useForSent.useSSL
            };
        }
        return emailsCurrent;
    }

    managePhone(data, providerId, isAddMode) {
        const phoneData = {}
        if(isAddMode){
            phoneData.providerId = providerId
        }
        phoneData.inUse = !data.inUse;
        phoneData.isMain = data.inMain;
        phoneData.isSupport = data.isSupport;
        phoneData.isMobile = data.isMobile;
        phoneData.forInvoice = data.forInvoice;
        phoneData.number = data.phone;
        return phoneData
    }

    fetchPhoneNumber(data) {
        const phoneData = {}
        phoneData.phone = data.number
        phoneData.value = data.id
        phoneData.inUse = !data.inUse
        phoneData.inMain = data.isMain
        phoneData.isMobile = data.isMobile
        phoneData.isSupport = data.isSupport
        phoneData.forInvoice = data.forInvoice
        phoneData.id = data.id
        return phoneData

    }

    fetchEmail(data) {
        const emailData = {
            useForSent:{}
        }
        emailData.emailValue = data.address;
        emailData.inUse = !data.inUse;
        emailData.inMain = data.isMain;
        emailData.isInfo = data.isInfo;
        emailData.isSupport = data.isSupport;
        emailData.forInvoice = data.forInvoice;
        emailData.value = data.id;
        emailData.id = data.id;
        emailData.useForSent.isForSent = data.forSend;
        emailData.useForSent.smtpServer = data.smtp && data.smtp.server ? data.smtp.server : "";
        emailData.useForSent.smtpPort = data.smtp && data.smtp.port ? data.smtp.port : "";
        emailData.useForSent.username = data.smtp && data.smtp.username ? data.smtp.username : "";
        emailData.useForSent.useForSentPassword =
            data.smtp && data.smtp.password ? data.smtp.password : "";
        emailData.useForSent.useSSL = data.smtp && data.smtp.ssl ? data.smtp.ssl : false

        return emailData

    }

}
