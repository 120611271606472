import { CompanyInfoModel } from "./companyInfoModel";
import { ContactInfoModel } from "./contactInfoModel";
import { PersonalInfoModel } from "./personalInfoModel";

export class AddProviderModel {
  constructor() {
    this.resetData();
  }
  saveData(companyInfo, contactInfo, personalInfo) {
    const companyInfoData = this.companyInfo.saveData(companyInfo);
    const contactInfoData = this.contactInfo.saveData(contactInfo);
    const personalInfoData = this.personalInfo.saveData(personalInfo);
    return { ...companyInfoData, ...contactInfoData, ...personalInfoData };
  }
  updateData(companyInfo, contactInfo, personalInfo, providerId) {
    const companyInfoData = this.companyInfo.saveData(companyInfo);
    const contactInfoData = this.contactInfo.saveData(contactInfo,providerId);
    const personalInfoData = this.personalInfo.updateData(personalInfo);
    return { ...companyInfoData, ...contactInfoData, ...personalInfoData };
  }
  resetData() {
    this.companyInfo = new CompanyInfoModel();
    this.contactInfo = new ContactInfoModel();
    this.personalInfo = new PersonalInfoModel();
  }
}
