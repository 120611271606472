<template>
  <div>
    <ott-switch
      v-model="switchValue"
      :label="switchLabel"
      @input="$emit('switchChange', $event)"
      class="mb-2"
    />

    <transition name="slide">
      <div v-if="switchValue" class="d-inline-flex">
        <ott-input
          v-model.number="fixedValue"
          @input="$emit('fixedInput', +$event)"
          :min="0"
          :label="fixedLabel"
          :error="fixedError"
          type="number"
          class="pe-1"
        />

        <ott-input
          v-model.number="percentValue"
          @input="$emit('percentInput', +$event)"
          :min="0"
          :label="percentLabel"
          :error="percentError"
          type="number"
          class="ps-1"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import OttInput from "@/components/vuetifyComponents/OttInput";

export default {
  name: "CustomFee",
  components: {
    OttSwitch,
    OttInput,
  },
  data() {
    return {
      switchValue: this.switchVal,
      fixedValue: this.fixedVal,
      percentValue: this.percentVal,
    };
  },
  props: {
    switchVal: {
      type: [Boolean, Object], //boolean, null
      default: () => false,
    },

    switchLabel: {
      type: String,
      required: () => true,
    },

    fixedVal: {
      type: Number,
      default: () => null,
    },

    fixedError: {
      type: Boolean,
      default: () => false,
    },

    fixedLabel: {
      type: String,
      default: () => null,
    },

    percentVal: {
      type: Number,
      default: () => null,
    },

    percentError: {
      type: Boolean,
      default: () => false,
    },

    percentLabel: {
      type: String,
      default: () => null,
    },
  },

  waych: {
    switchVal(newVal) {
      this.switchValue = newVal;
    },

    fixedVal(newVal) {
      this.fixedValue = newVal;
    },

    percentVal(newVal) {
      this.percentValue = newVal;
    },
  },
};
</script>
