<template>
  <div :class="menuCont">
    <v-menu offset-y left :attach="` .${menuCont} `">
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark icon v-bind="attrs" v-on="on">
          <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          @click="itemClickFunc(item.title, rowIndex, rowId)"
          v-for="(item, i) in menuItem"
          :key="i"
        >
          <div>
            <span v-if="hasIcon" :class="iconClass">icon</span>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </div>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "OttMenu",
  props: {
    rowId: {
      type: String,
      default: null
    },
    itemClickFunc: {
      type: Function,
      default: () => null
    },
    menuItem: {
      type: Array
    },
    hasIcon: {
      type: Boolean,
      default: false
    },
    iconClass: {
      type: String
    },
    rowIndex: {
      type: Number,
      default: null
    },
    attachedClass: {
      type: String
    },
    menuCont: {
      type: String
    }
  },
  data() {
    return {};
  }
};
</script>

<style scoped lang="scss">
@import "./src/assets/scss/variables";
@import "./src/assets/scss/mixins";

.theme--dark {
  &.v-list {
    background: $darkBgLighterDM;
  }
}
</style>
