<template>
    <div class="P-colors-list">
        <ul class="colors">
            <li
                v-for="item in colorsDefault"
                :key="item"
                :class="{'P-white': item ==='#FFFFFF'}"
                @click="selectColor(item)"
                class="item"
            >
                <div
                    :style="{ background: `url(${imgAlphaBase64})` }"
                    class="alpha"
                />
                <div
                    :style="{ background: item }"
                    class="color"
                />
            </li>
        </ul>
        <ul
            v-if="colorsHistory.length"
            class="colors history"
        >
            <li
                v-for="item in colorsHistory"
                :key="item"
                :class="{'P-white': item ==='rgba(255, 255, 255, 1)'}"
                @click="selectColor(item)"
                class="item"
            >
                <div
                    :style="{ background: `url(${imgAlphaBase64})` }"
                    class="alpha"
                />
                <div
                    :style="{ background: item }"
                    class="color"
                />
            </li>
        </ul>
    </div>
</template>

<script>
import mixin from './mixin'
export default {
    mixins: [mixin],
    props: {
        color: {
            type: String,
            default: '#000000'
        },
        colorsDefault: {
            type: Array,
            default: () => []
        },
        colorsHistoryKey: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            imgAlphaBase64: '',
            colorsHistory: JSON.parse(localStorage.getItem(this.colorsHistoryKey)) || []
        }
    },
    created() {
        this.imgAlphaBase64 = this.createAlphaSquare(4).toDataURL()
    },
    destroyed() {
        this.setColorsHistory(this.color)
    },
    methods: {
        selectColor(color) {
            this.$emit('selectColor', color)
        },
        setColorsHistory(color) {
            if (!color) {
                return
            }
            const colors = this.colorsHistory
            const index = colors.indexOf(color)
            if (index >= 0) {
                colors.splice(index, 1)
            }
            if (colors.length >= 8) {
                colors.length = 7
            }
            colors.unshift(color)
            this.colorsHistory = colors
            localStorage.setItem(this.colorsHistoryKey, JSON.stringify(colors))
        }
    }
}
</script>

<style lang="scss">
    .P-colors-list{
        margin-top: 10px;
    }
.colors {
    padding: 0 !important;
    margin: 0;
    &.history {
        margin-top: 10px;
        border-top: 1px solid #2e333a;
    }
    .item {
        position: relative;
        width: 20px;
        height: 20px;
        margin: 5px;
        border-radius: 3px;
        box-sizing: border-box;
        vertical-align: top;
        display: inline-block;
        transition: all 0.1s;
        cursor: pointer;
        overflow: hidden;
        border: 1px solid transparent;
        &.P-white{
            border-color: rgba(0,0,0,0.1);
            width: 19px;
            height: 19px;
        }

        &:hover {
            transform: scale(1.2);
        }
        .alpha {
            height: 100%;
            border-radius: 4px; // 大一像素，否则四个角会看到白点
        }
        .color {
            position: absolute;
            left: -1px;
            right: -1px;
            top: -1px;
            bottom: -1px;
            width: 22px;
            height: 22px;
        }
    }
}
</style>
