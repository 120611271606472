import { required, email } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      accordion: [],
      validationSpaces: {
        // productionToken: false,
        email: false,
        password: false,
        apiKey: false,
        apiSecret: false,
        apiToken: false,
      },
      showError: false
    };
  },

  validations: {
    // productionToken: { required },
    email: { required, email },
    password: { required },
    apiKey: { required },
    apiSecret: { required },
    apiToken: { required },
  },

  computed: {
    apiIntegrationsData() {
      return [
        {
          name: 'easyship',
          title: 'Easyship',
          logo: 'easyship.svg',
          icon: 'easyship_mini.svg',
          link: 'https://www.easyship.com',
          linkTitle: 'Website',
          rightButtonLabel: this.shipmentProviders.isEditEasyShip ? 'Save' : 'Add',
          rightButtonFunc: this.saveEasyShipData,
          leftButtonFunc: this.cancelEasyShipData,
          disabled: false,
          readonly: false,
          flexInputs: [],
          inputs: [
            {
              name: 'productionToken',
              label: 'Production token',
              value: this.productionToken,
              class: 'P-padding-100',
              type: 'text'
            },
          ],
          isValid: this.shipmentProviders.easyShipData.isValid
        },
        {
          name: 'shipRocket',
          title: 'ShipRocket',
          logo: 'shiprocket.svg',
          icon: 'shiprocket_mini.svg',
          link: 'https://www.shiprocket.in',
          linkTitle: 'Website',
          rightButtonLabel: this.shipmentProviders.isEditShipRocket ? 'Save' : 'Add',
          rightButtonFunc: this.saveShipRocketData,
          leftButtonFunc: this.cancelShipRocketData,
          disabled: true,
          readonly: true,
          flexInputs: [
            {
              name: 'email',
              label: 'Email Address*',
              value: this.email,
              class: 'P-padding-50',
              type: 'text'
            },
            {
              name: 'password',
              label: 'Password*',
              value: this.password,
              class: 'P-padding-50',
              type: 'password'
            }
          ],
          inputs: []
        },
        {
          name: 'shipStation',
          title: 'ShipStation',
          logo: 'shipstation.svg',
          icon: 'shipstation_mini.svg',
          link: 'https://www.shipstation.com',
          linkTitle: 'Website',
          rightButtonLabel: this.shipmentProviders.isEditShipStation ? 'Save' : 'Add',
          rightButtonFunc: this.saveShipStationData,
          leftButtonFunc: this.cancelShipStationData,
          disabled: true,
          readonly: true,
          flexInputs: [
            {
              name: 'apiKey',
              label: 'API key*',
              value: this.apiKey,
              class: 'P-padding-50',
              type: 'text'
            },
            {
              name: 'apiSecret',
              label: 'API secret*',
              value: this.apiSecret,
              class: 'P-padding-50',
              type: 'text'
            }
          ],
          inputs: []
        },
        {
          name: 'apiToken',
          title: 'GoShippo',
          logo: 'goshippo.svg',
          icon: 'goshippo_mini.svg',
          link: 'https://goshippo.com',
          linkTitle: 'Website',
          rightButtonLabel: this.shipmentProviders.isEditGoShippo ? 'Save' : 'Add',
          rightButtonFunc: this.saveGoShippoData,
          leftButtonFunc: this.cancelGoShippoData,
          disabled: true,
          readonly: true,
          flexInputs: [],
          inputs: [
            {
              name: 'apiToken',
              label: 'API Token*',
              value: this.apiToken,
              class: 'P-padding-100',
              type: 'text'
            }
          ]
        }
      ]
    },

    productionToken: {
      get() {
        return this.shipmentProviders.easyShipData.productionToken;
      },
      set(value) {
        this.setEasyShipDataProductionToken(value);
      },
    },
    email: {
      get() {
        return this.shipmentProviders.shipRocketData.email;
      },
      set(value) {
        this.setShipRocketDataEmail(value);
      },
    },

    password: {
      get() {
        return this.shipmentProviders.shipRocketData.password;
      },
      set(value) {
        this.setShipRocketDataPassword(value);
      },
    },

    apiKey: {
      get() {
        return this.shipmentProviders.shipStationData.apiKey;
      },
      set(value) {
        this.setShipStationDataApiKey(value);
      },
    },

    apiSecret: {
      get() {
        return this.shipmentProviders.shipStationData.apiSecret;
      },
      set(value) {
        this.setShipStationDataApiSecret(value);
      },
    },
    apiToken: {
      get() {
        return this.shipmentProviders.goShippoData.apiToken;
      },
      set(value) {
        this.setGoShippoDataApiToken(value);
      },
    },
  },

  watch: {
    async accordion(newVal) {
      this.showError = true
      this.updateBox()

      if (newVal === undefined && this.addProviderModal.providerId) {
        await this.getShippingProviders(this.addProviderModal.providerId)
          .then((data) => {
            this.fetchData(data);
          });
      }
    }
  },

  async mounted() {
    if (this.productionToken) {
      this.setIsEditEasyShip();
    }
    if (this.email) {
      this.setIsEditShipRocket();
    }
    if (this.apiKey) {
      this.setIsEditShipStation();
    }
    if (this.apiToken) {
      this.setIsEditGoShippo();
    }
    if (this.addProviderModal.providerId) {
      await this.getShippingProviders(this.addProviderModal.providerId)
        .then((data) => {
          this.fetchData(data);
        });
    }

    this.showError = true
  },

  methods: {
    async updateData() {
      this.showError = false
      this.toSaveData(this.addProviderModal.providerId);
      await this.updateShippingProvider(this.addProviderModal.providerId);
      await this.getShippingProviders(this.addProviderModal.providerId)
        .then((data) => {
        this.fetchData(data);
      });
    },

    updateBox() {
      this.$v.$reset();
      switch (this.accordion) {
        case 0: {
          // if (
          //   !this.$v.productionToken.$error &&
          //   !this.validationSpaces.productionToken
          // ) {
          this.saveEasyShipDataMutation(this.shipmentProviders.easyShipData);
          // } else {
          //   this.accordion = [0];
          // }
          break;
        }
        case 1: {
          // if (
          //   !this.$v.email.$error &&
          //   !this.$v.password.$error &&
          //   !this.validationSpaces.email &&
          //   !this.validationSpaces.password
          // ) {
            this.saveShipRocketDataMutation(this.shipmentProviders.shipRocketData);
          // } else {
          //   this.accordion = [1];
          // }
          break;
        }
        case 2: {
          // if (
          //   !this.$v.apiKey.$error &&
          //   !this.$v.apiSecret.$error &&
          //   !this.validationSpaces.apiKey &&
          //   !this.validationSpaces.apiSecret
          // ) {
            this.saveShipStationDataMutation(this.shipmentProviders.shipStationData);
          // } else {
          //   this.accordion = [2];
          // }
          break;
        }
        case 3: {
          // if (!this.$v.apiToken.$error && !this.validationSpaces.apiToken) {
            this.saveGoShippoDataMutation(this.shipmentProviders.goShippoData);
          // } else {
          //   this.accordion = [3];
          // }
          break;
        }
        default: {
        }
      }
    },

    async saveEasyShipData() {
      delete this.shipmentProviders.easyShipData.isValid
      // this.validateEasyShipData();
      // if (
      //   !this.$v.productionToken.$error &&
      //   !this.validationSpaces.productionToken
      // ) {
      //   this.accordion = [];
      delete this.shipmentProviders.easyShipData.productionKey
      this.saveEasyShipDataMutation(this.shipmentProviders.easyShipData);
      await this.updateData();
      this.accordion = []
      // }
    },
    cancelEasyShipData() {
      // this.$v.productionToken.$reset()
      this.cancelEasyShipDataMutation();
      this.accordion = [];
      // this.validationSpaces.productionToken = false;
    },

    async saveShipRocketData() {
      this.validateShipRocketData();
      if (
        !this.$v.email.$error &&
        !this.$v.password.$error &&
        !this.validationSpaces.email &&
        !this.validationSpaces.password
      ) {
        this.accordion = [];
        this.saveShipRocketDataMutation(this.shipmentProviders.shipRocketData);
        await this.updateData();
      }
    },
    cancelShipRocketData() {
      this.$v.email.$reset()
      this.$v.password.$reset()
      this.cancelShipRocketDataMutation();
      this.accordion = [];
      this.validationSpaces.email = false;
      this.validationSpaces.password = false;
    },

    async saveShipStationData() {
      this.validateShipStationData();
      if (
        !this.$v.apiKey.$error &&
        !this.$v.apiSecret.$error &&
        !this.validationSpaces.apiToken &&
        !this.validationSpaces.apiSecret
      ) {
        this.accordion = [];
        this.saveShipStationDataMutation(this.shipmentProviders.shipStationData);
        await this.updateData();
      }
    },
    cancelShipStationData() {
      this.$v.apiKey.$reset()
      this.$v.apiSecret.$reset()
      this.cancelShipStationDataMutation();
      this.accordion = [];
      this.validationSpaces.apiToken = false;
      this.validationSpaces.apiSecret = false;
    },

    async saveGoShippoData() {
      this.validateGoShippoData();
      if (!this.$v.apiToken.$error && !this.validationSpaces.apiToken) {
        this.accordion = [];
        this.saveGoShippoDataMutation(this.shipmentProviders.goShippoData);
        await this.updateData();
      }
    },
    cancelGoShippoData() {
      this.$v.apiToken.$reset()
      this.cancelGoShippoDataMutation();
      this.accordion = [];
      this.validationSpaces.apiToken = false;
    },

    // validateEasyShipData() {
    //   this.$v.productionToken.$touch();
    // },
    validateShipRocketData() {
      this.$v.email.$touch();
      this.$v.password.$touch();
    },
    validateShipStationData() {
      this.$v.apiKey.$touch();
      this.$v.apiSecret.$touch();
    },
    validateGoShippoData() {
      this.$v.apiToken.$touch();
    },
    validateSpace() {
      const [fileName] = arguments;
      if (this.$v[fileName]) {
        this.$v[fileName].$touch();
      }
      this.validationSpaces[fileName] = this[fileName].split(" ").length > 1;
    },
    changeInputValue(newValue, apiName) {
      this[apiName] = newValue
    }
  },
};