export class PersonalInfoModel {
  saveData(data) {
    const body = {};
    if (data.firstName) {
      body.firstname = data.firstName;
    }
    if (data.lastName) {
      body.lastname = data.lastName;
    }
    if (data.email) {
      body.email = data.email;
    }
    if (data.phone) {
      body.phone =  {
        phoneNumber: data.phone,
        countryCode: data.countryCode
      };
    }
    if (data.password) {
      body.password = data.password;
    }
    return body;
  }
  updateData(data) {
    const body = {};

    if (data.firstName) {
      body.firstname = data.firstName;
    }
    if (data.id) {
      body.id = data.id;
    }
    if (data.lastName) {
      body.lastname = data.lastName;
    }
    if (data.password) {
      body.password = data.password;
    }
    if (data.email) {
      body.email = data.email;
    }
    if (data.phone) {
      body.phone =  {
        phoneNumber: data.phone,
        countryCode: data.countryCode
      };
    }

    return { user: body };
  }
}
