export class CompanyInfoModel {
  constructor() {}
  saveData(data) {
    const body = {};
    if (data.companyName) {
      body.companyName = [{ lang: "us", name: data.companyName }];
    }
    if (data.website) {
      body.website = data.website;
    }
    if (data.country) {
      body.country = data.country;
    }
    if (data.numberOfTvChannel || data.numberOfTvChannel === 0) {
      body.channelAmount = data.numberOfTvChannel;
    }
    if (data.numberOfCustomers || data.numberOfCustomers === 0) {
      body.clientAmount = data.numberOfCustomers;
    }
    if (data.priceGroup) {
      if (data.priceGroup === 'default') {
        body.priceGroup = null
      } else {
        body.priceGroup = data.priceGroup.toString();
      }
    }
    if (data.timezone) {
      body.timezone = data.timezone;
    }
    if (data.brieflyDescribeProject) {
      body.description = data.brieflyDescribeProject;
    }
    if (data.comment) {
      body.comment = data.comment;
    }
    return body;
  }
}
