<template>
  <div
    :class="{ light: isLightTheme }"
    :style="{ width: totalWidth + 'px' }"
    class="hu-color-picker G-color-picker"
  >
    <div class="color-set">
      <Saturation
        ref="saturation"
        :color="rgbString"
        :hsv="hsv"
        :height="height"
        :width="width"
        @selectSaturation="selectSaturation"
      />
      <Hue
        ref="hue"
        :hsv="hsv"
        :width="hueWidth"
        :height="height"
        @selectHue="selectHue"
      />
      <Alpha
        v-if="!hideAlpha"
        ref="alpha"
        :color="rgbString"
        :rgba="rgba"
        :width="hueWidth"
        :height="height"
        @selectAlpha="selectAlpha"
      />
    </div>
    <div
      v-if="!hidePreview"
      :style="{ height: previewHeight + 'px' }"
      class="color-show"
    >
      <Preview
        :color="rgbaString"
        :width="previewWidth"
        :height="previewHeight"
      />
      <Sucker
        v-if="!suckerHide"
        :sucker-canvas="suckerCanvas"
        :sucker-area="suckerArea"
        @openSucker="openSucker"
        @selectSucker="selectSucker"
      />
    </div>
    <Box v-if="!hideHex" name="HEX" :color="modelHex" @inputColor="inputHex" />
    <Box
      v-if="!hideAlpha"
      name="RGBA"
      :color="modelRgba"
      @inputColor="inputRgba"
    />
    <Colors
      v-if="!hideColorsList"
      :color="rgbaString"
      :colors-default="colorsDefault"
      :colors-history-key="colorsHistoryKey"
      @selectColor="selectColor"
    />
  </div>
</template>

<script>
import mixin from "./mixin";
import Saturation from "./Saturation.vue";
import Hue from "./Hue.vue";
import Alpha from "./Alpha.vue";
import Preview from "./Preview.vue";
import Sucker from "./Sucker.vue";
import Box from "./Box.vue";
import Colors from "./Colors.vue";

export default {
  name: "OttColorPickerNew",
  components: {
    Saturation,
    Hue,
    Alpha,
    Preview,
    Sucker,
    Box,
    Colors,
  },
  mixins: [mixin],
  props: {
    color: {
      type: String,
      default: "#000000",
    },
    theme: {
      type: String,
      default: "dark",
    },
    suckerHide: {
      type: Boolean,
      default: true,
    },
    suckerCanvas: {
      type: null, // HTMLCanvasElement
      default: null,
    },
    suckerArea: {
      type: Array,
      default: () => [],
    },
    colorsDefault: {
      type: Array,
      default: () => [
        "#000000",
        "#FFFFFF",
        "#FF1900",
        "#F47365",
        "#FFB243",
        "#FFE623",
        "#6EFF2A",
        "#1BC7B1",
        "#00BEFF",
        "#2E81FF",
        "#5D61FF",
        "#FF89CF",
        "#FC3CAD",
        "#BF3DCE",
        "#8E00A7",
        "rgba(0,0,0,0)",
      ],
    },
    colorsHistoryKey: {
      type: String,
      default: "vue-colorpicker-history",
    },
    height: {
      type: Number,
      default: 156,
    },
    width: {
      type: Number,
      default: 156,
    },
    hideAlpha: {
      type: Boolean,
      default: false,
    },
    hideHex: {
      type: Boolean,
      default: false,
    },
    hidePreview: {
      type: Boolean,
      default: false,
    },
    hideColorsList: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hueWidth: 15,
      previewHeight: 30,
      modelRgba: "",
      modelHex: "",
      r: 0,
      g: 0,
      b: 0,
      a: 1,
      h: 0,
      s: 0,
      v: 0,
    };
  },
  computed: {
    isLightTheme() {
      return this.theme === "light";
    },
    totalWidth() {
      return this.hideAlpha
        ? this.width + (this.hueWidth + 15)
        : this.width + (this.hueWidth + 15) * 2;
    },
    previewWidth() {
      return this.totalWidth - (this.suckerHide ? 0 : this.previewHeight);
    },
    rgba() {
      return {
        r: this.r,
        g: this.g,
        b: this.b,
        a: this.a,
      };
    },
    hsv() {
      return {
        h: this.h,
        s: this.s,
        v: this.v,
      };
    },
    rgbString() {
      return `rgb(${this.r}, ${this.g}, ${this.b})`;
    },
    rgbaStringShort() {
      return `${this.r}, ${this.g}, ${this.b}, ${this.a}`;
    },
    rgbaString() {
      return `rgba(${this.rgbaStringShort})`;
    },
    hexString() {
      return this.rgb2hex(this.rgba, true);
    },
  },
  created() {
    Object.assign(this, this.setColorValue(this.color));
    this.setText();

    // 避免初始化时，也会触发changeColor事件
    this.$watch("rgba", () => {
      this.$emit("changeColor", {
        rgba: this.rgba,
        hsv: this.hsv,
        hex: this.modelHex,
      });
    });
  },
  methods: {
    selectSaturation(color) {
      const { r, g, b, h, s, v } = this.setColorValue(color);
      Object.assign(this, { r, g, b, h, s, v });
      this.setText();
    },
    selectHue(color) {
      const { r, g, b, h, s, v } = this.setColorValue(color);
      Object.assign(this, { r, g, b, h, s, v });
      this.setText();
      this.$nextTick(() => {
        this.$refs.saturation.renderColor();
        this.$refs.saturation.renderSlide();
      });
    },
    selectAlpha(a) {
      this.a = a;
      this.setText();
    },
    inputHex(color) {
      const { r, g, b, a, h, s, v } = this.setColorValue(color);
      Object.assign(this, { r, g, b, a, h, s, v });
      this.modelHex = color;
      this.modelRgba = this.rgbaStringShort;
      this.$nextTick(() => {
        this.$refs.saturation.renderColor();
        this.$refs.saturation.renderSlide();
        this.$refs.hue.renderSlide();
      });
    },
    inputRgba(color) {
      const { r, g, b, a, h, s, v } = this.setColorValue(color);
      Object.assign(this, { r, g, b, a, h, s, v });
      this.modelHex = this.hexString;
      this.modelRgba = color;
      this.$nextTick(() => {
        this.$refs.saturation.renderColor();
        this.$refs.saturation.renderSlide();
        this.$refs.hue.renderSlide();
      });
    },
    setText() {
      this.modelHex = this.hexString;
      this.modelRgba = this.rgbaStringShort;
    },
    openSucker(isOpen) {
      this.$emit("openSucker", isOpen);
    },
    selectSucker(color) {
      const { r, g, b, a, h, s, v } = this.setColorValue(color);
      Object.assign(this, { r, g, b, a, h, s, v });
      this.setText();
      this.$nextTick(() => {
        this.$refs.saturation.renderColor();
        this.$refs.saturation.renderSlide();
        this.$refs.hue.renderSlide();
      });
    },
    selectColor(color) {
      const { r, g, b, a, h, s, v } = this.setColorValue(color);
      Object.assign(this, { r, g, b, a, h, s, v });
      this.setText();
      this.$nextTick(() => {
        this.$refs.saturation.renderColor();
        this.$refs.saturation.renderSlide();
        this.$refs.hue.renderSlide();
      });
    },
  },
};
</script>

<style lang="scss">
@import "src/assets/scss/variables";

.theme--dark {
  .hu-color-picker {
    background-color: $dark_light !important;
  }
}
.hu-color-picker {
  border-radius: 4px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
  width: min-content !important;
  padding: 15px;
  z-index: 10;
  background-color: $white !important;
  position: absolute;
  left: -1px;
  top: -1px;
  .color-show {
    border-radius: 4px;
    overflow: hidden;
  }
  &.light {
    background: #f7f8f9;

    .color-show {
      .sucker {
        background: #eceef0;
      }
    }

    .color-type {
      .name {
        background: #e7e8e9;
      }

      .value {
        color: #666;
        background: #eceef0;
      }
    }

    .colors.history {
      border-top: 1px solid #eee;
    }
  }

  canvas {
    vertical-align: top;
  }

  .color-set {
    display: flex;
  }

  .color-show {
    margin-top: 8px;
    display: flex;
  }
}
.color-type {
  .name,
  .value {
    background-color: $white;
    display: flex;
    justify-content: flex-start;
  }
  .value {
    color: $secondary-color;
    border: 1px solid $neutral-color;
    outline: none !important;
    border-radius: 4px;
  }
}
</style>
