<template>
  <div class="G-color-picker">
    <color-picker
      :color="color"
      :sucker-hide="true"
      @changeColor="updateColor"
    />
  </div>
</template>
<script>
import colorPicker from "@caohenghu/vue-colorpicker";

export default {
  name: "OttColorPicker",
  components: {
    colorPicker
  },
  props: {
    isOpenPicker: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      color: "#000000"
    };
  },
  watch: {
    value(data) {
      this.color = data;
    }
  },
  mounted() {
    this.color = this.value;
  },
  methods: {
    updateColor(value) {
      this.$emit("input", value.hex);
      this.$emit("updateColor");
    }
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/variables";
//colorpicker

.G-color-picker::v-deep {
  .hu-color-picker {
    background: white !important;
    position: absolute;
    width: 245px !important;
    padding: 15px;
    height: 263px;
    right: -2px;
    top: -2px;
  }

  .color-show {
    width: 60px;
    float: left;
    margin-top: 0 !important;
    margin-right: 20px;

    canvas {
      width: 30px;
      border-radius: 4px;
    }
  }

  .colors {
    display: none;
  }

  .color-type,
  .color-show,
  .color-alpha {
    display: none !important;
  }
  /*<!--.color-show + .color-type {-->*/
  /*<!--  display: flex !important;-->*/
  /*<!--  width: 65.4%;-->*/

  /*<!--  .value {-->*/
  /*<!--    height: 30px;-->*/
  /*<!--    width: 60px;-->*/
  /*<!--    padding: 0 12px;-->*/
  /*<!--    color: #2e333a;-->*/
  /*<!--    background: #ffffff;-->*/
  /*<!--    border: 1px solid $neutral-color;-->*/
  /*<!--    border-radius: 4px;-->*/
  /*<!--  }-->*/

  /*<!--  .name {-->*/
  /*<!--    display: none;-->*/
  /*<!--  }-->*/
  /*<!--}-->*/

  .hue canvas,
  .color-alpha canvas {
    width: 12px;
    height: 100%;
    border-radius: 10px;
  }

  .sucker {
    width: 30px;
    fill: $neutral-color;
    background: #ffffff;
    cursor: pointer;
    margin: 0 10px;
    border: 1px solid $neutral-color;
    border-radius: 4px;
  }

  .color-set {
    height: 100%;
  }

  .saturation {
    canvas {
      width: 195px;
      height: 233px !important;
    }
  }
}
</style>
